import React from "react"
import { RichText, Elements } from "prismic-reactjs"

interface TextArrayProps {
    text: string
}

export const prismicGetText = (textArray: TextArrayProps[]): string => {
    if (!textArray) return ""

    return RichText.asText(textArray)
}

export const prismicGetRichText = (textArray: TextArrayProps[]): JSX.Element => {
    if (!textArray) return <p />

    return <RichText render={textArray} />
}

const customHtmlSerializer = (type: string, _element: any, _content: any, children: any, _key: any) => {
    switch (type) {
        case Elements.heading1:
        case Elements.heading2:
        case Elements.heading3:
        case Elements.heading4:
        case Elements.heading5: {
            return children
        }
        default:
            return null
    }
}

export const prismicGetRichHeader = (textArray: TextArrayProps[]): JSX.Element | string => {
    if (!textArray) return ""

    return <RichText render={textArray} htmlSerializer={customHtmlSerializer} />
}

interface PrismicProps {
    prismic: any
}

export const extractPrismicNode = (data: PrismicProps, nodeName: string): any => {
    const prismicData = data.prismic[nodeName].edges.slice(0, 1).pop()
    if (prismicData) {
        return prismicData.node
    } else {
        return null
    }
}

export const extractPrismicCollectionNode = (data: PrismicProps, nodeName: string): any => {
    return data.prismic[nodeName].edges
}

export const linkResolver = (doc: any) => {
    switch (doc.type.toLocaleLowerCase()) {
        case "blog_post":
            return "/posts/" + doc.uid
            break
        case "brief":
            return "/brief/" + doc.uid
            break
        case "webinar":
            return "/webinar/" + doc.uid
            break
        default:
            return "/"
    }
}
