module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Radicle","short_name":"Radicle","start_url":"/","display":"standalone","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-157161942-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PB3WPJT","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"radiclewebsite","accessToken":"MC5YZTlhc2hFQUFDSUF3VHJu.e2zvv73vv73vv73vv73vv73vv73vv73vv73vv70q77-977-9bgDvv73vv73vv71NJjch77-9RSllF--_ve-_vQfvv70","path":"/preview","previews":true,"pages":[{"type":"Blog_post","match":"/posts/:uid","path":"/posts","component":"/opt/build/repo/pages-gatsby/src/templates/blogPost.tsx"},{"type":"Brief","match":"/brief/:uid","path":"/brief","component":"/opt/build/repo/pages-gatsby/src/templates/brief.tsx"},{"type":"Webinar","match":"/webinar/:uid","path":"/webinar","component":"/opt/build/repo/pages-gatsby/src/templates/webinar.tsx"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
